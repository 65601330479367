<template>
  <div class="package-checkout-page">
    <section class="section-checkout-detail" v-if="info">
      <b-container class="pt-3 pb-2 checkout-title">
        <h4 class="title-stitle">
          {{ $t('booking.Fill_in_the_order_information') }}
        </h4>
      </b-container>
      <b-container class="checkout-detail-content mb-4 px-4">
        <div class="pro-feature ck-package py-4">
          <h4>
            {{ $t('booking.product_name') }}：<span
              >{{ info.packageCode
              }}<span class="ml-3">{{ info.title }}</span></span
            >
          </h4>
          <div class="row mt-3">
            <div class="col-md-6 ck-date">
              <p style="pointer-events: none">
                {{ $t('booking.departure_date') }}：
                <date-picker
                  v-model="datapost.departureDate"
                  v-if="info && datapost.departureDate"
                ></date-picker>
              </p>
            </div>

            <div
              class="col-md-6 text-md-right"
              :class="{ 'pointer-events': checkConfirm }"
            >
              <p>
                {{ $t('booking.headcount') }}：<button
                  class="ck-down-number"
                  @click="appendBookingDown"
                >
                  -
                </button>
                <input
                  type="number"
                  class="ck_ticket_number"
                  min="1"
                  readonly
                  v-model="bookingInfo.ticket"
                />
                <button
                  :disabled="amountToBook === 0"
                  @click="appendBookingUp"
                  class="ck-up-number"
                >
                  +
                </button>
              </p>
            </div>
            <div class="col-md-12 text-md-right">
              <p>{{ bookedCountCurrent }} / {{ max_booking }}</p>
            </div>
            <div class="col-md-12">
              <hr class="new2" />
            </div>
            <div class="col-md-12" :class="{ 'pointer-events': checkConfirm }">
              <div class="ck-section-2 ck-details">
                <div class="w-75 w-md-50 d-inline-block ck-row">
                  {{ $t('booking.Contact_name') }}:
                </div>
                <div class="form-row mt-3">
                  <div class="form-group col-md-4 form-input-contact-name">
                    <label for="contact_name"
                      >{{ $t('booking.Ch_En')
                      }}<span class="text-danger">* </span></label
                    >
                    <b-input-group>
                      <b-form-select
                        class="booking-phone-prefix"
                        v-model="bookingInfo.contactTitle"
                        :options="optionsTitleNameEng"
                        value-field="value"
                        text-field="text"
                      ></b-form-select>
                      <b-form-input
                        type="text"
                        class="form-control"
                        v-validate="{
                          required: true,
                        }"
                        name="surname"
                        :state="validateState('surname')"
                        v-model="bookingInfo.contactSurname"
                        aria-describedby="input-contact-name-Invalid"
                        :placeholder="
                          orderDataBefore
                            ? orderDataBefore.contactSurname
                            : 'Surname'
                        "
                      />
                      <b-form-input
                        type="text"
                        class="form-control"
                        v-validate="{
                          required: true,
                        }"
                        name="givenName"
                        :state="validateState('givenName')"
                        v-model="bookingInfo.contactGivenname"
                        aria-describedby="input-contact-name-Invalid"
                        :placeholder="
                          orderDataBefore
                            ? orderDataBefore.contactGivenname
                            : 'Given Name'
                        "
                      />
                      <b-form-invalid-feedback id="input-contact-name-Invalid">
                        <span class="md-guide">{{
                          $t('register.required')
                        }}</span>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                  <div
                    class="form-group col-md-4 ck-iput-t2 form-input-contact-phone"
                  >
                    <label for="contact_phone"
                      >{{ $t('booking.contact_phone')
                      }}<span class="text-danger">* </span></label
                    >
                    <b-form-group>
                      <b-input-group>
                        <b-form-select
                          v-model="bookingInfo.prefixPhone"
                          :options="options_phone"
                          class="booking-phone-prefix"
                        ></b-form-select>
                        <b-form-input
                          :placeholder="
                            orderDataBefore ? orderDataBefore.postfixPhone : ''
                          "
                          v-model="bookingInfo.postfixPhone"
                          v-validate="{
                            required: true,
                            numeric,
                            min: 8,
                            max: 11,
                          }"
                          style="width: 50%"
                          name="phoneNumber"
                          :state="validateState('phoneNumber')"
                          aria-describedby="input-phoneNumber-Invalid"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-phoneNumber-Invalid">
                          <span class="error">{{
                            $t('register.errorPhoneNumber')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="form-group col-md-4 form-input-contact-email">
                    <label for="contact_email"
                      >{{ $t('booking.Email_address')
                      }}<span class="text-danger">* </span></label
                    >
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          class="form-control"
                          v-validate="{
                            required: true,
                            email,
                          }"
                          name="email"
                          :state="validateState('email')"
                          aria-describedby="input-email-Invalid"
                          v-model="bookingInfo.contactEmail"
                          :placeholder="
                            orderDataBefore ? orderDataBefore.contactEmail : ''
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-email-Invalid">
                          <span class="error">{{
                            $t('register.errorEmail')
                          }}</span></b-form-invalid-feedback
                        >
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <!-- start: passengers info --->
            <div
              class="col-md-12 booking-form px-2"
              :class="{ 'pointer-events': checkConfirm }"
            >
              <div
                v-for="(item, index) in passengersInfo"
                :key="index"
                class="ck-section-1 mt-0 mt-md-4 ck-details mb-2"
                :class="[`ck-booking-${index + 1}`]"
              >
                <form
                  :id="`bk-form-${index + 1}`"
                  :class="[`bk-form-${index + 1} background-passenger`]"
                >
                  <div class="w-75 w-md-50 d-inline-block ck-row">
                    {{ $t('booking.passenger')
                    }}<span class="ck-booking-number">{{ index + 1 }} </span>
                    {{ $t('booking.please_fill_in_your_details') }}
                  </div>
                  <div
                    class="w-25 w-md-50 d-inline-block text-right ck-row font-weight-bold"
                  >
                    <a
                      class="clearinput"
                      :data-id="index + 1"
                      @click="clearInput(index + 1)"
                      href="javascript: void(0)"
                    >
                      {{ $t('booking.clear') }}
                    </a>
                  </div>
                  <div class="form-row mt-3">
                    <div
                      class="form-group col-md-4 ck-iput-3 bk-birthday"
                      :class="[`form-booking-birthday-${index + 1}`]"
                    >
                      <label class="pl-0"
                        >{{ $t('booking.date_of_birth')
                        }}<span class="text-danger">* </span></label
                      >
                      <date-picker
                        format="YYYY-MM-DD"
                        :placeholder="$t('booking.dateFormat')"
                        v-model="item.birthday"
                        value-type="YYYY-MM-DD"
                        class="booking-birthday w-100"
                        @change="changeBirthDay(item.birthday, index)"
                      ></date-picker>
                      <br />
                    </div>
                    <div
                      class="form-group col-md-4 ck-iput-1"
                      :class="[`form-booking-name-${index + 1}`]"
                    >
                      <label for=""
                        >{{ $t('booking.Ch_En') }}
                        <span class="text-info">*</span></label
                      >
                      <b-input-group>
                        <b-form-select
                          class="booking-phone-prefix"
                          v-model="item.titleEngName"
                          :options="item.optionsNameEng"
                          value-field="value"
                          text-field="text"
                          :change="changeTitleName(index)"
                        ></b-form-select>
                        <b-form-input
                          type="text"
                          class="form-control"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :name="'surnameEng' + index"
                          :state="validateState('surnameEng' + index)"
                          v-model="item.surnameEng"
                          :aria-describedby="'input-english-Invalid' + index"
                          :placeholder="
                            orderDataBefore &&
                            orderDataBefore.members &&
                            index < 1
                              ? orderDataBefore.members.surnameEng
                              : 'Surname'
                          "
                        />
                        <b-form-input
                          type="text"
                          class="form-control"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :name="'givenNameEng' + index"
                          :state="validateState('givenNameEng' + index)"
                          v-model="item.givenNameEng"
                          :aria-describedby="'input-english-Invalid' + index"
                          :placeholder="
                            orderDataBefore &&
                            orderDataBefore.members &&
                            index < 1
                              ? orderDataBefore.members.givenNameEng
                              : 'Given Name'
                          "
                        />
                        <b-form-invalid-feedback
                          :id="'input-english-Invalid' + index"
                        >
                          <span class="md-guide">{{
                            $t('register.required')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                    <div
                      class="form-group col-md-4 ck-iput-1"
                      :class="[`form-booking-name-eng-${index + 1}`]"
                    >
                      <label for=""
                        >{{ $t('booking.Name_booking') }}
                        <span class="text-info">*</span>
                      </label>
                      <b-input-group>
                        <b-form-select
                          class="booking-phone-prefix"
                          v-model="item.titleChinaName"
                          :options="item.optionsNameChina"
                          value-field="value"
                          text-field="text"
                        ></b-form-select>
                        <b-form-input
                          type="text"
                          class="form-control"
                          :name="'surnameChina' + index"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :state="validateState('surnameChina' + index)"
                          v-model="item.surnameChina"
                          :aria-describedby="'input-china-Invalid' + index"
                          :placeholder="
                            orderDataBefore &&
                            orderDataBefore.members &&
                            index < 1
                              ? orderDataBefore.members.surnameChina
                              : '姓'
                          "
                        />
                        <b-form-input
                          type="text"
                          class="form-control"
                          :name="'givenNameChina' + index"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :state="validateState('givenNameChina' + index)"
                          v-model="item.givenNameChina"
                          :aria-describedby="'input-china-Invalid' + index"
                          :placeholder="
                            orderDataBefore &&
                            orderDataBefore.members &&
                            index < 1
                              ? orderDataBefore.members.givenNameChina
                              : '名'
                          "
                        />
                        <b-form-invalid-feedback
                          :id="'input-china-Invalid' + index"
                        >
                          <span class="md-guide">{{
                            $t('register.required')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div
                      class="form-group col-md-4 ck-iput-t2 form-input-contact-phone"
                      :class="[`form-booking-phone-${index + 1}`]"
                    >
                      <label for=""
                        >{{ $t('booking.Mobile_number')
                        }}<span class="text-danger">* </span></label
                      >
                      <b-input-group>
                        <b-form-select
                          v-model="item.prefixPhone"
                          :options="options_phone"
                          class="booking-phone-prefix"
                        ></b-form-select>
                        <b-form-input
                          debounce="1000"
                          :placeholder="
                            orderDataBefore &&
                            orderDataBefore.members &&
                            index < 1
                              ? orderDataBefore.members.contact
                              : ''
                          "
                          v-model="item.contact"
                          v-validate="{
                            required: true,
                            numeric,
                            min: 8,
                            max: 11,
                          }"
                          style="width: 50%"
                          :name="'contact' + index"
                          :state="validateState('contact' + index)"
                          :aria-describedby="'input-contact-Invalid' + index"
                          @change="checkUserMember(index)"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :id="`input-contact-Invalid` + index"
                        >
                          <span class="error">{{
                            $t('register.errorPhoneNumber')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                    <div
                      class="form-group col-md-4 ck-iput-6"
                      :class="[`form-booking-email-${index + 1}`]"
                    >
                      <label for="">{{ $t('booking.Email_address') }} </label>
                      <input
                        debounce="1000"
                        type="email"
                        class="form-control"
                        name="email[]"
                        v-model="item.email"
                        :placeholder="
                          orderDataBefore &&
                          orderDataBefore.members &&
                          index < 1
                            ? orderDataBefore.members.email
                            : ''
                        "
                        @change="checkUserMember(index)"
                      />
                      <span class="mt-2 pt-2 ck-error"></span>
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div class="form-group col-md-3 ck-iput-7">
                      <label>{{ $t('booking.typeOfCertificate') }}</label>
                      <b-form-select
                        v-if="!item.typeOfCertificateOther"
                        v-model="item.typeOfCertificate"
                        :options="optionsTypeOfCertificate"
                        class="booking-phone-prefix"
                        @change="changeCercificate(index)"
                      ></b-form-select>
                      <div class="d-flex" v-else>
                        <b-form-input
                          v-model="item.typeOfCertificate"
                          class="booking-phone-prefix"
                          :placeholder="$t('booking.nameOfCertificate')"
                        ></b-form-input>
                        <b-input-group-prepend>
                          <b-button
                            class="p-1"
                            @click="changeOptionCertificate(index)"
                            >x</b-button
                          >
                        </b-input-group-prepend>
                      </div>
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label>{{ $t('booking.placeOfIssue') }}</label>
                      <b-form-select
                        v-model="item.placeOfIssue"
                        :options="optionsPlaceOfIssue"
                        class="booking-phone-prefix"
                      ></b-form-select>
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label for="">{{ $t('booking.Passport_number') }} </label>
                      <input
                        type="text"
                        class="form-control"
                        name="passport[]"
                        v-model="item.passportNumber"
                        :id="`booking-passport-${index + 1}`"
                        placeholder=""
                      />
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label for="">{{ $t('booking.Date_of_expiry') }} </label>
                      <date-picker
                        format="YYYY-MM-DD"
                        placeholder=""
                        v-model="item.dateOfExpiry"
                        value-type="YYYY-MM-DD"
                        class="booking-passport-date"
                        style="width: 100%;"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="form-row mt-3 w-100">
                    <table
                      class="table table-bordered table-hover table-responsive table-hover mb-5 ck-table limitClass"
                    >
                      <thead>
                        <tr>
                          <th width="7%">{{ $t('booking.Name') }}</th>
                          <th width="8%">{{ $t('booking.Price_per_pax') }}</th>
                          <th width="22%">{{ $t('booking.House_type') }}</th>
                          <th width="6%">{{ $t('booking.Tax') }}</th>
                          <th width="6%">{{ $t('booking.Tips') }}</th>
                          <th width="6%">
                            <!-- <div class="d-flex">
                              <input
                                style="width: 15px"
                                type="checkbox"
                                :id="`others-${index + 1}`"
                                v-model="item.othersCheck"
                                :name="`others-${index + 1}`"
                                @change="checkOthers(index)"
                              />

                              <label class="p-1" :for="`others-${index + 1}`">
                                {{ $t('booking.Others') }}
                              </label>
                            </div> -->

                            <b-form-checkbox
                              v-model="item.othersCheck"
                              :value="1"
                              :unchecked-value="0"
                              @change="(e) => checkOthers(index, e)"
                              size="sm"
                            >
                              {{ $t('booking.Others') }}
                            </b-form-checkbox>
                          </th>
                          <th width="8%">
                            <!-- <div class="d-flex">
                              <input
                                style="width: 15px"
                                type="checkbox"
                                :id="`visa-${index + 1}`"
                                v-model="item.visaCheck"
                                :name="`visa-${index + 1}`"
                                @change="checkVisaFee(index)"
                              />

                              <label class="p-1" :for="`visa-${index + 1}`">
                                {{ $t('booking.Visa') }}
                              </label>
                            </div> -->
                            <b-form-checkbox
                              v-model="item.visaCheck"
                              :value="1"
                              :unchecked-value="0"
                              @change="(e) => checkVisaFee(index, e)"
                              size="sm"
                            >
                              {{ $t('booking.Visa') }}
                            </b-form-checkbox>
                          </th>
                          <th width="7%">
                            <!-- <div class="d-flex">
                              <input
                                style="width: 15px"
                                type="checkbox"
                                :id="`insurance-${index + 1}`"
                                v-model="item.insuranceCheck"
                                :name="`insurance-${index + 1}`"
                                @change="checkInsuranceFee(index)"
                              />
                              <label
                                class="p-1"
                                :for="`insurance-${index + 1}`"
                              >
                                {{ $t('booking.Insurance') }}
                              </label>
                            </div> -->
                            <b-form-checkbox
                              v-model="item.insuranceCheck"
                              :value="1"
                              :unchecked-value="0"
                              @change="(e) => checkInsuranceFee(index, e)"
                              size="sm"
                            >
                              {{ $t('booking.Insurance') }}
                            </b-form-checkbox>
                          </th>
                          <th width="7%">
                            {{ $t('booking.promotionDiscount') }}
                          </th>
                          <th width="9%">
                            <!-- <div class="d-flex">
                              <input
                                style="width: 15px"
                                type="checkbox"
                                :id="`discount-${index + 1}`"
                                v-model="item.memberDiscountCheck"
                                :name="`discount-${index + 1}`"
                                @change="(e) => checkUserMember(index, e)"
                              />
                              <label
                                class="p-1"
                                style="width: 60px"
                                :for="`discount-${index + 1}`"
                              >
                                {{ $t('booking.memberDiscount') }}
                              </label>
                            </div> -->
                            <b-form-checkbox
                              v-model="item.memberDiscountCheck"
                              :value="1"
                              :unchecked-value="0"
                              @change="(e) => checkUserMember(index, e)"
                              size="sm"
                            >
                              {{ $t('booking.memberDiscount') }}
                            </b-form-checkbox>
                          </th>
                          <th width="9%">
                            <!-- <div class="d-flex">
                              <input
                                style="width: 15px"
                                type="checkbox"
                                :disabled="userType === 'USER'"
                                :id="`special-${index + 1}`"
                                v-model="item.memberSpecialDiscountCheck"
                                :name="`special-${index + 1}`"
                                @change="checkUserMemberDiscount(index)"
                              />
                              <label
                                class="p-1"
                                style="width: 60px"
                                :for="`special-${index + 1}`"
                              >
                                {{ $t('booking.specialDiscount') }}
                              </label>
                            </div> -->

                            <b-form-checkbox
                              v-model="item.memberSpecialDiscountCheck"
                              :disabled="userType === 'USER'"
                              :value="1"
                              :unchecked-value="0"
                              @change="(e) => checkUserMemberDiscount(index, e)"
                              size="sm"
                            >
                              {{ $t('booking.specialDiscount') }}
                            </b-form-checkbox>
                          </th>
                          <th width="5%">{{ $t('booking.Sum') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b-input-group>
                              <b-form-input
                                v-if="localLanguage === 'en'"
                                class="p-2 text-center font12"
                                readonly
                                v-model="item.category"
                              ></b-form-input>
                              <b-form-input
                                v-else
                                class="p-2 text-center font12"
                                readonly
                                v-model="item.categoryChina"
                              ></b-form-input>
                            </b-input-group>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span>
                            <span
                              class="adultPrice"
                              :id="`departures-memberprice-${index + 1}`"
                              >{{ item.unitPrice }}</span
                            >
                          </td>
                          <td class="text-left px-1 py-4">
                            <div>
                              <!-- <b-form-checkbox-group
                                v-model="item.extraBedType"
                                :options="optionsExtrabed"
                                :name="'roomType' + index"
                                @change="reCalculateToTalAmountPassenger(index)"
                                stacked
                              >
                                <span>
                                  {{ item.extraBedPrice }}
                                </span>
                              </b-form-checkbox-group> -->
                              <b-form-checkbox
                                v-model="item.extraBedType"
                                :value="1"
                                :unchecked-value="0"
                                @change="
                                  (e) => reCalculateToTalAmountPassenger(index)
                                "
                                size="sm"
                              >
                                {{ $t('booking.Extra_room') }}
                                <span class="extra_fee">
                                  {{ item.extraBedPrice }}
                                </span>
                              </b-form-checkbox>
                            </div>
                            <div>
                              <!-- <b-form-checkbox-group
                                v-model="item.singleRoomType"
                                :options="optionsSingleRoom"
                                :name="'roomType' + index"
                                @change="reCalculateToTalAmountPassenger(index)"
                                stacked
                              >
                                <span>
                                  {{ item.singleRoomPrice }}
                                </span>
                              </b-form-checkbox-group> -->
                              <b-form-checkbox
                                v-model="item.singleRoomType"
                                :value="1"
                                :unchecked-value="0"
                                @change="
                                  (e) => reCalculateToTalAmountPassenger(index)
                                "
                                size="sm"
                              >
                                {{ $t('booking.Single_room') }}
                                <span class="extra_fee">
                                  {{ item.singleRoomPrice }}
                                </span>
                              </b-form-checkbox>
                            </div>
                            <div>
                              <!-- <b-form-checkbox-group
                                v-model="item.childrenBedType"
                                :options="optionsChildrenRoom"
                                :name="'roomType' + index"
                                @change="reCalculateToTalAmountPassenger(index)"
                                stacked
                                ><span
                                  >{{ item.childrenBedPrice }}
                                </span></b-form-checkbox-group
                              > -->
                              <b-form-checkbox
                                v-model="item.childrenBedType"
                                :value="1"
                                :unchecked-value="0"
                                @change="
                                  (e) => reCalculateToTalAmountPassenger(index)
                                "
                                size="sm"
                              >
                                {{ $t('booking.Children_Occupy_Bed') }}
                                <span class="extra_fee">
                                  {{ item.childrenBedPrice }}
                                </span>
                              </b-form-checkbox>
                            </div>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-tax">{{ item.tax }} </span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-tips">{{ item.tip }}</span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-otherPrice">{{ item.other }}</span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-visaFee">{{ item.visaFee }}</span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-insurance">{{
                              item.insurance
                            }}</span>
                          </td>
                          <td>
                            $<span class="bk-visaFee"
                              >{{ item.promotionDiscount }}
                            </span>
                          </td>
                          <td>
                            $<span class="bk-visaFee"
                              >{{ item.memberDiscount }}
                            </span>
                          </td>
                          <td>
                            <span
                              :style="{
                                color:
                                  item.specialDiscount > 0 ? 'red' : 'black',
                              }"
                            >
                              $<span class="bk-visaFee"
                                >{{ item.specialDiscount }}
                              </span>
                            </span>
                          </td>
                          <td>
                            $<span
                              class="bk-amount"
                              :id="`departures-total-${index + 1}`"
                              >{{ item.amount }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="form-row mt-3">
                    <div class="form-group col-md-3 ck-iput-7">
                      <label>{{ $t('booking.collectionPoints') }}</label>
                      <b-form-select
                        v-model="item.collectionPoint"
                        :options="collectionPointsList"
                        class="booking-phone-prefix"
                        @change="
                          (value) => handleCollectPointChange(value, index)
                        "
                        v-validate="{
                          required: validatepoint(index),
                        }"
                      ></b-form-select>
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label>{{ $t('booking.seatRequirement') }}</label>
                      <b-form-select
                        v-model="item.seatRequirement"
                        :options="seatRequirementList"
                        class="booking-phone-prefix"
                      ></b-form-select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <b-col cols="12" class="text-right" v-show="!checkConfirm">
              <b-button
                type="submit"
                v-if="!busy"
                @click="confirmInput()"
                variant="warning"
                class="my-4 ck-submit btn-warning"
                >{{ $t('booking.confirmInput') }}</b-button
              >
            </b-col>
            <!-- end: passengers info --->
            <div class="col-md-12">
              <hr class="new2" />
            </div>
            <div
              class="form-group col-md-12"
              style="display: flex; align-items: center"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="bookingInfo.isCollectionAndSeat"
                name="checkbox-1"
                :true-value="true"
                :false-value="false"
                style="width: 450px"
                unchecked-value=""
              >
                {{ $t('booking.collectionSeat') }}
              </b-form-checkbox>
              <b-form-input
                readonly
                v-model="bookingInfo.collectionAndSeat"
                v-if="bookingInfo.isCollectionAndSeat"
              ></b-form-input>
            </div>
            <div class="col-md-12">
              <div class="ck-section-2 ck-details">
                <div class="form-row mt-4">
                  <div class="form-group col-md-12">
                    <label for="">{{ $t('booking.Other_remarks') }}</label>
                    <textarea
                      maxlength="500"
                      class="form-control"
                      rows="5"
                      v-model="bookingInfo.remarks"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <hr class="new2" />
            </div>
            <div class="col-md-12" v-if="checkConfirm">
              <div class="col-md-12">
                <div class="ck-section-3 ck-details">
                  <div
                    class="form-row mt-4 mb-4"
                    v-if="selected_payment_method == 'online'"
                  >
                    <div class="form-group col-md-12 ck-payment-type">
                      <label for="" class="d-block">{{
                        $t('booking.Payment_amount')
                      }}</label>
                      <b-form-radio
                        v-model="selected_payment"
                        value="deposit"
                        class="d-md-inline mr-4 ck-s3-radio-text deposit-payment"
                        v-if="
                          info.allowDepositSwitch == 'Y' &&
                          minDepositPayment > 0 &&
                          minDepositPayment < grandTotal
                        "
                        >{{ $t('booking.Deposit_payment') }}
                        <span
                          >{{
                            $t('booking.At_least_30_of_the_full_payment')
                          }}${{ minDepositPayment
                          }}{{
                            $t('booking.At_least_30_of_the_full_payment_1')
                          }}</span
                        >
                        <div
                          style="width: 300px"
                          v-if="selected_payment === 'deposit'"
                        >
                          <b-form-group class="paymentAmountCustomer-group">
                            <b-input-group class="mt-3">
                              <b-form-input
                                type="number"
                                v-validate="{
                                  required: true,
                                  numeric: true,
                                  integer,
                                }"
                                name="amount"
                                :state="validateState('amount')"
                                :placeholder="$t('booking.Payment_amount_text')"
                                @change="inputChangeDepositPayment"
                                v-model="depositPayment"
                                aria-describedby="input-amount-Invalid"
                              ></b-form-input>
                              <b-form-invalid-feedback
                                id="input-amount-Invalid"
                              >
                                <div class="error">
                                  {{ $t('booking.checkPaymentAmount') }}
                                </div>
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selected_payment"
                        value="full"
                        class="d-md-inline ck-s3-radio-text full-payment"
                        >{{ $t('booking.Full_payment') }}</b-form-radio
                      >
                    </div>
                  </div>
                  <div class="form-row mt-4 mb-4">
                    <div class="form-group col-md-12 ck-payment-method">
                      <label for="" class="d-block">{{
                        $t('booking.Pattern_of_payment')
                      }}</label>
                      <b-form-radio
                        v-model="selected_payment_method"
                        value="online"
                        class="d-md-inline mr-4"
                        checked
                        >{{ $t('booking.Online_payment') }}</b-form-radio
                      >
                      <b-form-radio
                        v-model="selected_payment_method"
                        value="store"
                        class="d-md-inline mt-3 mt-md-0 ml-0 ml-md-5"
                        >{{ $t('booking.Shop_payment') }}</b-form-radio
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <hr class="new2" />
                <span
                  class="d-block c-red ck-error mb-2"
                  v-if="selected_payment_method == 'store'"
                >
                  {{
                    $t('booking.pleasePayTheFee', [
                      departures !== null ? departures.orderExpiredAfter : '',
                    ])
                  }}</span
                >
              </div>
              <b-row class="w-100 d-flex justify-content-end">
                <b-col cols="12" md="4">
                  <b-row
                    align-v="center"
                    v-if="
                      selected_payment === 'deposit' &&
                      selected_payment_method == 'online'
                    "
                  >
                    <b-col cols="9" class="text-right pr-0">
                      <div class="total-payment-sum">
                        {{ $t('booking.Grand_total') }}:
                      </div>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <span class="currency-symbol c-red">$</span>
                      <span class="c-red bk-total-amount-sum">{{
                        grandTotal
                      }}</span>
                    </b-col>
                    <b-col cols="9" class="text-right pr-0">
                      <div class="total-payment-sum">
                        {{ $t('booking.TIAPaid') }}:
                      </div>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <span class="currency-symbol c-red">$</span>
                      <span class="c-red bk-total-amount-sum">{{
                        tiaLevyPaid
                      }}</span>
                    </b-col>
                    <b-col cols="9" class="text-right pr-0">
                      <div class="total-payment-sum">
                        {{ $t('booking.Deposit_payment') }}:
                      </div>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <span class="currency-symbol c-red">$</span>
                      <!-- <span
                        class="c-red bk-deposit-amount-sum"
                        v-if="depositPayment % 2 === 0"
                        > -->
                      <span class="c-red bk-deposit-amount-sum">
                        {{ depositPayment }}
                      </span>
                    </b-col>
                    <b-col cols="9" class="text-right pr-0">
                      <div class="total-payment-sum">
                        {{ $t('booking.Balance_payment') }}:
                      </div>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <span class="currency-symbol c-red">$</span>
                      <span class="c-red bk-balance-amount-sum"
                        >{{ balancePayment }}
                      </span>
                    </b-col>
                  </b-row>
                  <b-row align-v="center" v-else>
                    <b-col cols="9" class="text-right pr-0">
                      <div class="total-payment-sum">
                        {{ $t('booking.Grand_total') }}:
                      </div>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <span class="currency-symbol c-red">$</span>
                      <span class="c-red bk-total-amount-sum">{{
                        grandTotal
                      }}</span>
                    </b-col>
                    <b-col cols="9" class="text-right pr-0">
                      <div class="total-payment-sum">
                        {{ $t('booking.TIAPaid') }}:
                      </div>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <span class="currency-symbol c-red">$</span>
                      <span class="c-red bk-total-amount-sum">{{
                        tiaLevyPaid
                      }}</span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="bk-col-submit p-lg-0"
                  v-show="checkConfirm"
                >
                  <div class="text-right">
                    <b-button
                      type="submit"
                      v-if="!busy"
                      @click="submitForm()"
                      variant="warning"
                      class="my-4 ck-submit btn-warning"
                      >{{ $t('booking.Submit_order') }}</b-button
                    >
                    <b-button
                      class="my-4 ck-submit btn-warning"
                      v-else
                      variant="warning"
                      disabled
                    >
                      <b-spinner small type="grow"></b-spinner>
                      {{ $t('Register_and_Login.loading') }}...
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- start: warning --->
            <b-modal ref="modal-notify_bk" :hide-footer="true">
              <div class="row text-center justify-content-center">
                <img
                  src="../../src/assets/img/icon/icon-hint-red.png"
                  style="width: 60px"
                  alt="img"
                />
                <br />
                <p class="my-4 text-center w-100">
                  {{ $t('booking.Are_you_sure') }}
                </p>
              </div>
              <hr />
              <div class="row text-center">
                <div class="col-6">
                  <b-button variant="secondary" @click="hideModal()">{{
                    $t('payment.Close')
                  }}</b-button>
                </div>
                <div class="col-6">
                  <b-button
                    variant="warning"
                    @click="submitPayment()"
                    v-if="!busy"
                    >{{ $t('booking.ConfirmAndPay') }}
                  </b-button>
                  <b-button v-else variant="warning" disabled>
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t('Register_and_Login.loading') }}...
                  </b-button>
                </div>
              </div>
            </b-modal>
            <!-- end: warning --->
            <!-- store -->
            <b-modal ref="modal-success-store" :hide-footer="true">
              <div class="row text-center justify-content-center px-4 py-3">
                <img
                  class=""
                  src="@/assets/img/icon/checked.png"
                  style="width: 60px"
                  alt="Image"
                /><span class="ml-2 mt-3" style="font-size: 1.2rem">
                  {{
                    $t('payment.your_order_has_been_submitted_successfully')
                  }}</span
                ><br />
                <p class="my-4 text-center w-100">
                  {{
                    $t('booking.pleasePayTheFee', [
                      departures !== null ? departures.orderExpiredAfter : '',
                    ])
                  }}
                </p>
              </div>
            </b-modal>
            <!-- end store  -->
            <b-modal
              ref="modal-waring-bk"
              id="modal-waring-bk"
              :hide-footer="true"
            >
              <div class="row text-center justify-content-center">
                <h4 class="text-warning">{{ $t('booking.Warning') }}</h4>
                <p class="my-4 text-center w-100">
                  {{
                    $t(
                      'booking.Children_under_18_must_be_accompanied_by_at_least_one_adult',
                    )
                  }}
                </p>
              </div>
            </b-modal>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import enums from '@/store/enums';
const phone_prefix = enums.phone_prefix;
import { debounce } from 'vue-debounce';
import moment, { min } from 'moment';
export default {
  name: 'checkout',
  components: {
    DatePicker,
  },
  // beforeDestroy() {
  //   sessionStorage.removeItem('departureId');
  // },
  data() {
    return {
      checkConfirm: false,
      departures: null,
      price: null,
      time: [],
      depositPayment: 0,
      balancePayment: 0,
      initTotalAmountPerPassenger: 0,
      selected_payment: 'deposit',
      selected_payment_method: 'online',
      minDepositPayment: 0,
      tiaLevyPaid: 0,
      minDepositTour: 0,
      max_booking: null,
      checkercentage: 0,
      disabled_ticket: true,
      fmember: false,
      grandTotal: 0,
      orderNumber: null,
      options_phone: [
        { value: '+' + phone_prefix.hk, text: '+' + phone_prefix.hk },
        { value: '+' + phone_prefix.mc, text: '+' + phone_prefix.mc },
        { value: '+' + phone_prefix.cn, text: '+' + phone_prefix.cn },
      ],
      lang: this.$language,
      bookingInfo: {
        ticket: 1,
        departureDate: null,
        prefixPhone: '+' + phone_prefix.hk,
        postfixPhone: '',
        contactEmail: '',
        remarks: '',
        contactTitle: 'MR',
        contactSurname: '',
        contactGivenname: '',
        collectionAndSeat: '',
        isCollectionAndSeat: true,
      },
      passengersInfo: [
        {
          category: '',
          categoryChina: '',
          seatRequirement: '',
          collectionPoint: '',
          age: 0,
          titleEngName: 'MR',
          surnameEng: '',
          givenNameEng: '',
          titleChinaName: 'MR',
          surnameChina: '',
          givenNameChina: '',
          birthday: moment(new Date("1960-1-1")).format('YYYY-MM-DD'),
          prefixPhone: '+' + phone_prefix.hk,
          contact: '',
          email: '',
          passportNumber: '',
          dateOfExpiry: '',
          unitPrice: 0,
          extraBedType: 0,
          singleRoomType: 0,
          childrenBedType: 0,
          tax: 0,
          visaFee: 0,
          visaCheck: 0,
          othersCheck: 0,
          tip: 0,
          insurance: 0,
          insuranceCheck: 1,
          other: 0,
          amount: 0,
          extraBedPrice: 0,
          singleRoomPrice: 0,
          childrenBedPrice: 0,
          typeOfCertificate: "HKIC",
          typeOfCertificateOther: 0,
          placeOfIssue: "HK",
          seatRequirement: null,
          promotionDiscount: 0,
          memberDiscountCheck: 0,
          memberDiscount: 0,
          memberSpecialDiscountCheck: 0,
          specialDiscount: 0,
          fuelSurchargeAdult: 0,
          fuelSurchargeChildren: 0,
          securityChargeAdult: 0,
          securityChargeChildren: 0,
          departureTaxAdult: 0,
          departureTaxChildren: 0,
          portTaxAdult: 0,
          portTaxChildren: 0,
          optionsNameEng: [
            { value: 'MR', text: 'MR' },
            { value: 'MRS', text: 'MRS' },
            { value: 'MS', text: 'MS' },
            { value: 'MSTR', text: 'MSTR' },
            { value: 'MISS', text: 'MISS' },
          ],
          optionsNameChina: [
            { value: 'MR', text: '先生' },
            { value: 'MRS', text: '女士' },
            { value: 'MS', text: '小姐' },
            { value: 'MSTR', text: '男童' },
            { value: 'MISS', text: '女童' },
          ],
        },
      ],
      datapost: {
        departureDate: '',
        packageCode: '',
      },
      dataOrder: {},
      extraBedType: 0,
      singleRoomType: 0,
      optionsExtrabed: [{ text: this.$t('booking.Extra_room'), value: 1 }],
      optionsSingleRoom: [{ text: this.$t('booking.Single_room'), value: 1 }],
      optionsChildrenRoom: [
        { text: this.$t('booking.Children_Occupy_Bed'), value: 1 },
      ],
      busy: 0,
      bookedCountCurrent: 0,
      optionsTitleNameEng: [
        { value: 'MR', text: 'MR' },
        { value: 'MRS', text: 'MRS' },
        { value: 'MS', text: 'MS' },
        { value: 'MSTR', text: 'MSTR' },
        { value: 'MISS', text: 'MISS' },
      ],
      optionsTitleNameChina: [
        { value: 'MR', text: '先生' },
        { value: 'MRS', text: '女士' },
        { value: 'MS', text: '小姐' },
        { value: 'MSTR', text: '男童' },
        { value: 'MISS', text: '女童' },
      ],
      optionsTypeOfCertificate: [
        {
          value: null,
          key: 'CN',
          text: this.$t('booking.pleaseSelect'),
          disabled: true,
        },
        { value: 'HRP', key: 'CN', text: this.$t('place_certificate.HRP') },
        { value: 'HKIC', key: 'HK', text: this.$t('place_certificate.HKIC') },
        { value: 'HKSAR', key: 'HK', text: this.$t('place_certificate.HKSAR') },
        { value: 'HKDOI', key: 'HK', text: this.$t('place_certificate.HKDOI') },
        { value: 'MSP', key: 'MO', text: this.$t('place_certificate.MSP') },
        { value: 'MTP', key: 'MO', text: this.$t('place_certificate.MTP') },
        { value: 'TP', key: 'CN', text: this.$t('place_certificate.TP') },
        { value: 'CP', key: 'CN', text: this.$t('place_certificate.CP') },
        { value: 'AU', key: 'AU', text: this.$t('place_certificate.AU') },
        { value: 'CA', key: 'CA', text: this.$t('place_certificate.CA') },
        { value: 'CH', key: 'CH', text: this.$t('place_certificate.CH') },
        { value: 'DE', key: 'DE', text: this.$t('place_certificate.DE') },
        { value: 'FR', key: 'FR', text: this.$t('place_certificate.FR') },
        { value: 'UK', key: 'GB', text: this.$t('place_certificate.UK') },
        { value: 'ID', key: 'ID', text: this.$t('place_certificate.ID') },
        { value: 'JP', key: 'JP', text: this.$t('place_certificate.JP') },
        { value: 'MY', key: 'MY', text: this.$t('place_certificate.MY') },
        { value: 'NL', key: 'NL', text: this.$t('place_certificate.NL') },
        { value: 'PT', key: 'PT', text: this.$t('place_certificate.PT') },
        { value: 'SG', key: 'SG', text: this.$t('place_certificate.SG') },
        { value: 'TH', key: 'TH', text: this.$t('place_certificate.TH') },
        { value: 'TW', key: 'TW', text: this.$t('place_certificate.TW') },
        { value: 'US', key: 'US', text: this.$t('place_certificate.US') },
        { value: 'NZ', key: 'NZ', text: this.$t('place_certificate.NZ') },
        {
          value: 'OTHER',
          key: 'OTHER',
          text: this.$t('place_certificate.OTHER'),
        },
      ],
      optionsPlaceOfIssue: [
        { value: null, text: this.$t('booking.pleaseSelect'), disabled: true },
        { value: 'AF', text: this.$t('place.AF') },
        { value: 'AL', text: this.$t('place.AL') },
        { value: 'DZ', text: this.$t('place.DZ') },
        { value: 'AD', text: this.$t('place.AD') },
        { value: 'AO', text: this.$t('place.AO') },
        { value: 'AI', text: this.$t('place.AI') },
        { value: 'AQ', text: this.$t('place.AQ') },
        { value: 'AG', text: this.$t('place.AG') },
        { value: 'AR', text: this.$t('place.AR') },
        { value: 'AM', text: this.$t('place.AM') },
        { value: 'AW', text: this.$t('place.AW') },
        { value: 'AU', text: this.$t('place.AU') },
        { value: 'AT', text: this.$t('place.AT') },
        { value: 'AZ', text: this.$t('place.AZ') },
        { value: 'BS', text: this.$t('place.BS') },
        { value: 'BH', text: this.$t('place.BH') },
        { value: 'BD', text: this.$t('place.BD') },
        { value: 'BB', text: this.$t('place.BB') },
        { value: 'BY', text: this.$t('place.BY') },
        { value: 'BE', text: this.$t('place.BE') },
        { value: 'BZ', text: this.$t('place.BZ') },
        { value: 'BJ', text: this.$t('place.BJ') },
        { value: 'BM', text: this.$t('place.BM') },
        { value: 'BT', text: this.$t('place.BT') },
        { value: 'BO', text: this.$t('place.BO') },
        { value: 'BA', text: this.$t('place.BA') },
        { value: 'BW', text: this.$t('place.BW') },
        { value: 'BR', text: this.$t('place.BR') },
        { value: 'IO', text: this.$t('place.IO') },
        { value: 'BN', text: this.$t('place.BN') },
        { value: 'BG', text: this.$t('place.BG') },
        { value: 'BF', text: this.$t('place.BF') },
        { value: 'BI', text: this.$t('place.BI') },
        { value: 'KH', text: this.$t('place.KH') },
        { value: 'CM', text: this.$t('place.CM') },
        { value: 'CA', text: this.$t('place.CA') },
        { value: 'CV', text: this.$t('place.CV') },
        { value: 'KY', text: this.$t('place.KY') },
        { value: 'CF', text: this.$t('place.CF') },
        { value: 'TD', text: this.$t('place.TD') },
        { value: 'CL', text: this.$t('place.CL') },
        { value: 'CN', text: this.$t('place.CN') },
        { value: 'CX', text: this.$t('place.CX') },
        { value: 'CC', text: this.$t('place.CC') },
        { value: 'CO', text: this.$t('place.CO') },
        { value: 'KM', text: this.$t('place.KM') },
        { value: 'CG', text: this.$t('place.CG') },
        { value: 'CK', text: this.$t('place.CK') },
        { value: 'CR', text: this.$t('place.CR') },
        { value: 'HR', text: this.$t('place.HR') },
        { value: 'CU', text: this.$t('place.CU') },
        { value: 'CY', text: this.$t('place.CY') },
        { value: 'CZ', text: this.$t('place.CZ') },
        { value: 'DR', text: this.$t('place.DR') },
        { value: 'DK', text: this.$t('place.DK') },
        { value: 'DJ', text: this.$t('place.DJ') },
        { value: 'DM', text: this.$t('place.DM') },
        { value: 'DO', text: this.$t('place.DO') },
        { value: 'ET', text: this.$t('place.ET') },
        { value: 'EC', text: this.$t('place.EC') },
        { value: 'EG', text: this.$t('place.EG') },
        { value: 'SV', text: this.$t('place.SV') },
        { value: 'GQ', text: this.$t('place.GQ') },
        { value: 'ER', text: this.$t('place.ER') },
        { value: 'EE', text: this.$t('place.EE') },
        { value: 'EA', text: this.$t('place.EA') },
        { value: 'FO', text: this.$t('place.FO') },
        { value: 'FK', text: this.$t('place.FK') },
        { value: 'FJ', text: this.$t('place.FJ') },
        { value: 'FI', text: this.$t('place.FI') },
        { value: 'FR', text: this.$t('place.FR') },
        { value: 'GF', text: this.$t('place.GF') },
        { value: 'PF', text: this.$t('place.PF') },
        { value: 'GA', text: this.$t('place.GA') },
        { value: 'GM', text: this.$t('place.GM') },
        { value: 'GE', text: this.$t('place.GE') },
        { value: 'DE', text: this.$t('place.DE') },
        { value: 'GH', text: this.$t('place.GH') },
        { value: 'GI', text: this.$t('place.GI') },
        { value: 'GR', text: this.$t('place.GR') },
        { value: 'GL', text: this.$t('place.GL') },
        { value: 'GD', text: this.$t('place.GD') },
        { value: 'GP', text: this.$t('place.GP') },
        { value: 'GU', text: this.$t('place.GU') },
        { value: 'GT', text: this.$t('place.GT') },
        { value: 'GN', text: this.$t('place.GN') },
        { value: 'GW', text: this.$t('place.GW') },
        { value: 'GY', text: this.$t('place.GY') },
        { value: 'HT', text: this.$t('place.HT') },
        { value: 'HM', text: this.$t('place.HM') },
        { value: 'HK', text: this.$t('place.HK') },
        { value: 'HU', text: this.$t('place.HU') },
        { value: 'IS', text: this.$t('place.IS') },
        { value: 'IN', text: this.$t('place.IN') },
        { value: 'ID', text: this.$t('place.ID') },
        { value: 'IR', text: this.$t('place.IR') },
        { value: 'IQ', text: this.$t('place.IQ') },
        { value: 'IE', text: this.$t('place.IE') },
        { value: 'IL', text: this.$t('place.IL') },
        { value: 'IT', text: this.$t('place.IT') },
        { value: 'IC', text: this.$t('place.IC') },
        { value: 'JM', text: this.$t('place.JM') },
        { value: 'JP', text: this.$t('place.JP') },
        { value: 'JO', text: this.$t('place.JO') },
        { value: 'KZ', text: this.$t('place.KZ') },
        { value: 'KE', text: this.$t('place.KE') },
        { value: 'KI', text: this.$t('place.KI') },
        { value: 'KN', text: this.$t('place.KN') },
        { value: 'KS', text: this.$t('place.KS') },
        { value: 'KW', text: this.$t('place.KW') },
        { value: 'KG', text: this.$t('place.KG') },
        { value: 'LA', text: this.$t('place.LA') },
        { value: 'LV', text: this.$t('place.LV') },
        { value: 'LB', text: this.$t('place.LB') },
        { value: 'LS', text: this.$t('place.LS') },
        { value: 'LR', text: this.$t('place.LR') },
        { value: 'LY', text: this.$t('place.LY') },
        { value: 'LI', text: this.$t('place.LI') },
        { value: 'LT', text: this.$t('place.LT') },
        { value: 'LU', text: this.$t('place.LU') },
        { value: 'MO', text: this.$t('place.MO') },
        { value: 'MK', text: this.$t('place.MK') },
        { value: 'MG', text: this.$t('place.MG') },
        { value: 'MW', text: this.$t('place.MW') },
        { value: 'MY', text: this.$t('place.MY') },
        { value: 'MV', text: this.$t('place.MV') },
        { value: 'ML', text: this.$t('place.ML') },
        { value: 'MT', text: this.$t('place.MT') },
        { value: 'MH', text: this.$t('place.MH') },
        { value: 'MQ', text: this.$t('place.MQ') },
        { value: 'MR', text: this.$t('place.MR') },
        { value: 'MU', text: this.$t('place.MU') },
        { value: 'YT', text: this.$t('place.YT') },
        { value: 'MX', text: this.$t('place.MX') },
        { value: 'FM', text: this.$t('place.FM') },
        { value: 'MD', text: this.$t('place.MD') },
        { value: 'MC', text: this.$t('place.MC') },
        { value: 'MN', text: this.$t('place.MN') },
        { value: 'MS', text: this.$t('place.MS') },
        { value: 'MA', text: this.$t('place.MA') },
        { value: 'MZ', text: this.$t('place.MZ') },
        { value: 'MM', text: this.$t('place.MM') },
        { value: 'NA', text: this.$t('place.NA') },
        { value: 'NR', text: this.$t('place.NR') },
        { value: 'NP', text: this.$t('place.NP') },
        { value: 'NL', text: this.$t('place.NL') },
        { value: 'NS', text: this.$t('place.NS') },
        { value: 'NC', text: this.$t('place.NC') },
        { value: 'NZ', text: this.$t('place.NZ') },
        { value: 'NI', text: this.$t('place.NI') },
        { value: 'NE', text: this.$t('place.NE') },
        { value: 'NG', text: this.$t('place.NG') },
        { value: 'NU', text: this.$t('place.NU') },
        { value: 'NF', text: this.$t('place.NF') },
        { value: 'MP', text: this.$t('place.MP') },
        { value: 'NO', text: this.$t('place.NO') },
        { value: 'OM', text: this.$t('place.OM') },
        { value: 'PK', text: this.$t('place.PK') },
        { value: 'PW', text: this.$t('place.PW') },
        { value: 'PA', text: this.$t('place.PA') },
        { value: 'PG', text: this.$t('place.PG') },
        { value: 'PY', text: this.$t('place.PY') },
        { value: 'PE', text: this.$t('place.PE') },
        { value: 'PH', text: this.$t('place.PH') },
        { value: 'PL', text: this.$t('place.PL') },
        { value: 'PT', text: this.$t('place.PT') },
        { value: 'PR', text: this.$t('place.PR') },
        { value: 'QA', text: this.$t('place.QA') },
        { value: 'RE', text: this.$t('place.RE') },
        { value: 'RO', text: this.$t('place.RO') },
        { value: 'RU', text: this.$t('place.RU') },
        { value: 'RW', text: this.$t('place.RW') },
        { value: 'SA', text: this.$t('place.SA') },
        { value: 'SW', text: this.$t('place.SW') },
        { value: 'SM', text: this.$t('place.SM') },
        { value: 'ST', text: this.$t('place.ST') },
        { value: 'SU', text: this.$t('place.SU') },
        { value: 'SN', text: this.$t('place.SN') },
        { value: 'SC', text: this.$t('place.SC') },
        { value: 'SL', text: this.$t('place.SL') },
        { value: 'SG', text: this.$t('place.SG') },
        { value: 'SK', text: this.$t('place.SK') },
        { value: 'SI', text: this.$t('place.SI') },
        { value: 'SB', text: this.$t('place.SB') },
        { value: 'SO', text: this.$t('place.SO') },
        { value: 'ZA', text: this.$t('place.ZA') },
        { value: 'ES', text: this.$t('place.ES') },
        { value: 'LK', text: this.$t('place.LK') },
        { value: 'KA', text: this.$t('place.KA') },
        { value: 'LC', text: this.$t('place.LC') },
        { value: 'PI', text: this.$t('place.PI') },
        { value: 'VI', text: this.$t('place.VI') },
        { value: 'SD', text: this.$t('place.SD') },
        { value: 'SR', text: this.$t('place.SR') },
        { value: 'SZ', text: this.$t('place.SZ') },
        { value: 'SE', text: this.$t('place.SE') },
        { value: 'CH', text: this.$t('place.CH') },
        { value: 'SY', text: this.$t('place.SY') },
        { value: 'TW', text: this.$t('place.TW') },
        { value: 'TJ', text: this.$t('place.TJ') },
        { value: 'TZ', text: this.$t('place.TZ') },
        { value: 'TH', text: this.$t('place.TH') },
        { value: 'TG', text: this.$t('place.TG') },
        { value: 'TO', text: this.$t('place.TO') },
        { value: 'TT', text: this.$t('place.TT') },
        { value: 'TN', text: this.$t('place.TN') },
        { value: 'TR', text: this.$t('place.TR') },
        { value: 'TM', text: this.$t('place.TM') },
        { value: 'TC', text: this.$t('place.TC') },
        { value: 'TV', text: this.$t('place.TV') },
        { value: 'UG', text: this.$t('place.UG') },
        { value: 'UA', text: this.$t('place.UA') },
        { value: 'AE', text: this.$t('place.AE') },
        { value: 'GB', text: this.$t('place.GB') },
        { value: 'US', text: this.$t('place.US') },
        { value: 'UY', text: this.$t('place.UY') },
        { value: 'UM', text: this.$t('place.UM') },
        { value: 'UZ', text: this.$t('place.UZ') },
        { value: 'VU', text: this.$t('place.VU') },
        { value: 'VA', text: this.$t('place.VA') },
        { value: 'VE', text: this.$t('place.VE') },
        { value: 'VN', text: this.$t('place.VN') },
        { value: 'VK', text: this.$t('place.VK') },
        { value: 'VS', text: this.$t('place.VS') },
        { value: 'WF', text: this.$t('place.WF') },
        { value: 'YE', text: this.$t('place.YE') },
        { value: 'YU', text: this.$t('place.YU') },
        { value: 'ZE', text: this.$t('place.ZE') },
        { value: 'ZM', text: this.$t('place.ZM') },
        { value: 'ZW', text: this.$t('place.ZW') },
      ],
      collectionPointsList: [],
      seatRequirementList: [],
      userType: null,
      localLanguage: 'en',
      orderDataBefore: null,
    };
  },
  computed: {
    info() {
      return this.$store.getters['tourModule/getDepartureData'];
    },
    amountToBook() {
      return this.max_booking - this.bookedCountCurrent;
    },
    uniqueCollectionPoints() {
      const uniquePoints = new Set(
        this.passengersInfo.map((p) => p.collectionPoint).filter((v) => v),
      );
      return Array.from(uniquePoints).join(', ');
    },
  },
  created() {
    this.$validator.extend('greaterOrEqualThanFour', {
      getMessage: (field) =>
        field + ' needs to be > 30% grand total & < grand total.',
      validate: (value) => {
        this.balancePayment = this.grandTotal - this.depositPayment;
        if (value == this.minDepositPayment) return true;
        return false;
      },
    });
    this.$validator.extend('numeric_min', {
      validate: (value, [min]) => {
        if (parseInt(value) > min || parseInt(value) >= this.grandTotal) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  watch: {
    passengersInfo: {
      handler(value) {
        this.grandTotal = 0;
        value.forEach((e) => {
          this.grandTotal += e.amount;
        });

        if (
          this.departures.ticSwitch !== 'N' &&
          this.departures.paidTIA === 'N'
        ) {
          this.tiaLevyPaid = Math.ceil(this.grandTotal * 0.0015);
          this.grandTotal = Math.ceil(
            this.grandTotal + this.grandTotal * 0.0015,
          );
        }

        if (!this.grandTotal || this.minDepositPayment >= this.grandTotal) {
          this.depositPayment = 0;
        } else {
          this.depositPayment = this.minDepositPayment;
        }

        this.balancePayment =
          this.grandTotal <= 0 ? 0 : this.grandTotal - this.depositPayment;
      },
      deep: true,
    },
    uniqueCollectionPoints(newVal) {
      this.bookingInfo.collectionAndSeat = newVal;
    },
  },
  methods: {
    validateName(index) {
      let surnameEng = this.passengersInfo[index].surnameEng;
      let givenNameEng = this.passengersInfo[index].givenNameEng;
      let surnameChina = this.passengersInfo[index].surnameChina;
      let givenNameChina = this.passengersInfo[index].givenNameChina;
      if ((surnameEng && givenNameEng) || (surnameChina && givenNameChina)) {
        return false;
      }
      return true;
    },
    validatepoint(index) {
      let isCollectionAndSeat = this.bookingInfo.isCollectionAndSeat;
      if (isCollectionAndSeat) {
        return true;
      }
      return false;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    inputChangeDepositPayment() {
      debounce((val) => {
        if (Number(val) === this.grandTotal) {
          this.selected_payment = 'full';
        }

        if (
          !Number.isInteger(val) ||
          val > this.grandTotal ||
          val < this.minDepositPayment
        ) {
          this.depositPayment = this.minDepositPayment;
        }
        if (value < this.minDepositPayment) {
          this.depositPayment = val;
        }
        if (this.depositPayment === this.grandTotal) {
          this.depositPayment = 0;
        }
        this.balancePayment = this.grandTotal - this.depositPayment;
      }, 100)(this.depositPayment);
    },
    clearInput: function (sid) {
      this.passengersInfo[sid - 1].category = '';
      this.passengersInfo[sid - 1].categoryChina = '';
      this.passengersInfo[sid - 1].age = 0;
      this.passengersInfo[sid - 1].titleEngName = 'MR';
      this.passengersInfo[sid - 1].titleChinaName = 'MR';
      this.passengersInfo[sid - 1].surnameEng = '';
      this.passengersInfo[sid - 1].givenNameEng = '';
      this.passengersInfo[sid - 1].surnameChina = '';
      this.passengersInfo[sid - 1].givenNameChina = '';
      this.passengersInfo[sid - 1].typeOfCertificate = null;
      this.passengersInfo[sid - 1].typeOfCertificateOther = false;
      this.passengersInfo[sid - 1].placeOfIssue = null;
      this.passengersInfo[sid - 1].collectionPoint = null;
      this.passengersInfo[sid - 1].seatRequirement = null;
      this.passengersInfo[sid - 1].birthday = new Date();
      this.passengersInfo[sid - 1].contact = '';
      this.passengersInfo[sid - 1].email = '';
      this.passengersInfo[sid - 1].passportNumber = '';
      this.passengersInfo[sid - 1].dateOfExpiry = '';
      this.passengersInfo[sid - 1].optionsNameEng = [
        { value: 'MR', text: 'MR' },
        { value: 'MRS', text: 'MRS' },
        { value: 'MS', text: 'MS' },
        { value: 'MSTR', text: 'MSTR' },
        { value: 'MISS', text: 'MISS' },
      ];
      this.passengersInfo[sid - 1].optionsNameChina = [
        { value: 'MR', text: '先生' },
        { value: 'MRS', text: '女士' },
        { value: 'MS', text: '小姐' },
        { value: 'MSTR', text: '男童' },
        { value: 'MISS', text: '女童' },
      ];
    },
    showModal() {
      this.$refs['modal-notify_bk'].show();
    },
    hideModal() {
      this.$refs['modal-notify_bk'].hide();
    },
    showModalWarning() {
      this.$refs['modal-waring-bk'].show();
    },
    hideModalWarning() {
      this.$refs['modal-waring-bk'].hide();
    },
    showModalStore() {
      this.$refs['modal-success-store'].show();
    },
    hideModalStore() {
      this.$refs['modal-success-store'].hide();
    },
    appendBookingDown: function () {
      if (this.bookingInfo.ticket === 1) {
        return;
      }
      this.bookedCountCurrent -= 1;

      this.bookingInfo.ticket -= 1;
      this.minDepositPayment -= this.minDepositTour;
      this.passengersInfo.pop();

      this.inputChangeDepositPayment();
    },
    appendBookingUp: function () {
      if (this.bookedCountCurrent === this.max_booking) {
        this.$vToastify.error({
          body:
            this.$t('booking.error_max') +
            ' ' +
            this.amountToBook +
            ' ' +
            this.$t('booking.person'),
          defaultTitle: false,
        });
        return;
      }
      this.bookedCountCurrent += 1;

      this.bookingInfo.ticket += 1;

      this.minDepositPayment = this.minDepositTour * this.bookingInfo.ticket;
      this.passengersInfo.push({
        category: 'Adult',
        categoryChina: '(成人)',
        age: 0,
        titleEngName: 'MR',
        surnameEng: '',
        givenNameEng: '',
        titleChinaName: 'MR',
        surnameChina: '',
        givenNameChina: '',
        birthday: moment(String(new Date('1960-01-01'))).format('YYYY-MM-DD'),
        prefixPhone: '+' + phone_prefix.hk,
        contact: '',
        email: '',
        passportNumber: '',
        dateOfExpiry: '',
        extraBedType: this.extraBedType,
        singleRoomType: this.singleRoomType,
        childrenBedType: this.childrenBedType,
        tax: this.departures.adultTax
          ? this.departures.adultTax
          : 0,
        visaFee: 0,
        tip: this.departures.tips,
        insurance: this.departures.adultInsuranceFee
          ? this.departures.adultInsuranceFee
          : 0,
        other: 0,
        amount: 0,
        extraBedPrice: this.departures.extraBed,
        singleRoomPrice: this.departures.singleRoom,
        childrenBedPrice: this.departures.childrenOccupyBed,
        typeOfCertificate: 'HKIC',
        typeOfCertificateOther: 0,
        placeOfIssue: "HK",
        collectionPoint: null,
        visaCheck: 0,
        othersCheck: 0,
        insuranceCheck: 1,
        promotionDiscount: this.departures.promotionDiscount
          ? this.departures.promotionDiscount
          : 0,
        memberDiscountCheck: 0,
        memberDiscount: 0,
        memberSpecialDiscountCheck: 0,
        specialDiscount: 0,
        fuelSurchargeAdult: this.departures.fuelSurchargeAdult,
        fuelSurchargeChildren: this.departures.fuelSurchargeChildren,
        securityChargeAdult: this.departures.securityChargeAdult,
        securityChargeChildren: this.departures.securityChargeChildren,
        departureTaxAdult: this.departures.departureTaxAdult,
        departureTaxChildren: this.departures.departureTaxChildren,
        portTaxAdult: this.departures.portTaxAdult,
        portTaxChildren: this.departures.portTaxChildren,
        optionsNameEng: [
          { value: 'MR', text: 'MR' },
          { value: 'MRS', text: 'MRS' },
          { value: 'MS', text: 'MS' },
          { value: 'MSTR', text: 'MSTR' },
          { value: 'MISS', text: 'MISS' },
        ],
        optionsNameChina: [
          { value: 'MR', text: '先生' },
          { value: 'MRS', text: '女士' },
          { value: 'MS', text: '小姐' },
          { value: 'MSTR', text: '男童' },
          { value: 'MISS', text: '女童' },
        ],
        unitPrice: this.departures.adultPrice,
      });
      const index = this.bookingInfo.ticket - 1;
      this.reCalculateToTalAmountPassenger(index);

      this.inputChangeDepositPayment();
    },

    updateNewData: function (data, dummy) {
      return data ? data : dummy;
    },

    confirmInput: function () {
      if (this.orderDataBefore) {
        this.bookingInfo.contactTitle = this.updateNewData(
          this.bookingInfo.contactTitle,
          this.orderDataBefore.contactTitle,
        );
        this.bookingInfo.contactSurname = this.updateNewData(
          this.bookingInfo.contactSurname,
          this.orderDataBefore.contactSurname,
        );
        this.bookingInfo.contactGivenname = this.updateNewData(
          this.bookingInfo.contactGivenname,
          this.orderDataBefore.contactGivenname,
        );
        this.bookingInfo.prefixPhone = this.updateNewData(
          this.bookingInfo.prefixPhone,
          this.orderDataBefore.prefixPhone,
        );
        this.bookingInfo.postfixPhone = this.updateNewData(
          this.bookingInfo.postfixPhone,
          this.orderDataBefore.postfixPhone,
        );
        this.bookingInfo.contactEmail = this.updateNewData(
          this.bookingInfo.contactEmail,
          this.orderDataBefore.contactEmail,
        );
        this.passengersInfo[0].birthday = this.updateNewData(
          this.passengersInfo[0].birthday,
          this.orderDataBefore.members.birthday,
        );
        this.passengersInfo[0].surnameEng = this.updateNewData(
          this.passengersInfo[0].surnameEng,
          this.orderDataBefore.members.surnameEng,
        );
        this.passengersInfo[0].givenNameEng = this.updateNewData(
          this.passengersInfo[0].givenNameEng,
          this.orderDataBefore.members.givenNameEng,
        );
        this.passengersInfo[0].surnameChina = this.updateNewData(
          this.passengersInfo[0].surnameChina,
          this.orderDataBefore.members.surnameChina,
        );
        // this.passengersInfo[0].collectionPoint = this.updateNewData(
        //   this.passengersInfo[0].collectionPoint,
        //   this.orderDataBefore.members.collectionPoint,
        // );
        this.passengersInfo[0].givenNameChina = this.updateNewData(
          this.passengersInfo[0].givenNameChina,
          this.orderDataBefore.members.givenNameChina,
        );

        this.passengersInfo[0].contact = this.updateNewData(
          this.passengersInfo[0].contact,
          this.orderDataBefore.members.contact,
        );

        this.passengersInfo[0].email = this.updateNewData(
          this.passengersInfo[0].email,
          this.orderDataBefore.members.email,
        );

        // this.passengersInfo[0].typeOfCertificate = this.updateNewData(
        //   this.passengersInfo[0].typeOfCertificate,
        //   this.orderDataBefore.members.typeOfCertificate,
        // );

        // this.passengersInfo[0].placeOfIssue = this.updateNewData(
        //   this.passengersInfo[0].placeOfIssue,
        //   this.orderDataBefore.members.placeOfIssue,
        // );

        // this.passengersInfo[0].passportNumber = this.updateNewData(
        //   this.passengersInfo[0].passportNumber,
        //   this.orderDataBefore.members.passportNumber,
        // );
      }

      if (
        !this.passengersInfo.some((passenger) => passenger.category === 'Adult')
      ) {
        this.$vToastify.error({
          body: this.lang.payment.not_adult,
          defaultTitle: false,
        });
        return;
      }
      let iscollPoint = this.passengersInfo.every(
        (passenger) =>
          passenger.collectionPoint !== null &&
          passenger.collectionPoint !== '',
      );
      if (this.bookingInfo.isCollectionAndSeat && !iscollPoint) {
        this.$vToastify.error({
          body: this.lang.payment.not_point,
          defaultTitle: false,
        });
        return;
      }
      if (this.grandTotal < 0) {
        this.$vToastify.error({
          body: this.lang.payment.not_confirm,
          defaultTitle: false,
        });
        return;
      }
      this.$nextTick(() => {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return;
          }
          if (this.minDepositPayment > this.grandTotal) {
            this.selected_payment = 'full';
          }
          this.checkConfirm = true;
        });
      });
    },

    submitForm() {
      // console.log(this.dataOrder)
      // return
      if (this.grandTotal <= 0) {
        this.$vToastify.error({
          body: this.lang.payment.not_confirm,
          defaultTitle: false,
        });
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        sessionStorage.removeItem('timeline');
        this.dataOrder.isCollectionAndSeat =
          this.bookingInfo.isCollectionAndSeat;
        this.dataOrder.collectionAndSeat = this.bookingInfo.collectionAndSeat;
        this.dataOrder.seatRequirement = this.bookingInfo.seatRequirement;
        this.dataOrder.collectionPoint = this.bookingInfo.collectionPoint;
        this.dataOrder.contactTitle = this.bookingInfo.contactTitle;
        this.dataOrder.contactSurname = this.bookingInfo.contactSurname;
        this.dataOrder.contactGivenname = this.bookingInfo.contactGivenname;
        this.dataOrder.contactPhone =
          this.bookingInfo.prefixPhone + this.bookingInfo.postfixPhone;
        this.dataOrder.contactEmail = this.bookingInfo.contactEmail;
        this.dataOrder.otherRemarks = this.bookingInfo.remarks;
        this.dataOrder.packageId = this.departures.packageId;
        this.dataOrder.departureDateId = this.departures.id;
        this.dataOrder.grandTotal = this.grandTotal;
        this.dataOrder.tiaLevyPaid = this.tiaLevyPaid;
        this.dataOrder.minDepositPayment = this.minDepositPayment;
        this.dataOrder.members = this.passengersInfo;
        this.dataOrder.membersCount = this.passengersInfo.length;
        this.dataOrder.branch = this.departures.branch;
        this.dataOrder.firstSelectedPaymentTransaction = this.selected_payment;
        if (this.selected_payment_method === 'store') {
          this.submitPaymentStore();
        } else {
          this.showModal();
        }
      });
    },
    submitPaymentStore() {
      this.busy = true;
      this.$store
        .dispatch('orderModule/saveDefaultOrder', this.dataOrder)
        .then(() => {
          this.showModalStore();
          setTimeout(() => {
            // window.location.assign(window.location.origin + '/#/my-order');
            this.$router.push('/my-order');
          }, 2000);
          this.busy = false;
        })
        .catch((error) => {
          this.busy = false;
          if (error.message.includes('The available seats')) {
            const availableSeats = parseInt(error.message.split(' ')[5], 10);
            if (availableSeats > 0) {
              this.bookedCountCurrent = this.max_booking;
              this.bookingInfo.ticket = availableSeats;
              this.passengersInfo = this.passengersInfo.slice(
                0,
                availableSeats,
              );
            }
          }

          this.$vToastify.setSettings({
            errorDuration: 15000,
            successDuration: 10000,
          });
          this.$vToastify.error({
            body: error.message,
            defaultTitle: false,
          });
        });
    },
    submitPayment() {
      // const dataDummy = {
      //   contactTitle: this.bookingInfo.contactTitle,
      //   contactSurname: this.bookingInfo.contactSurname,
      //   contactGivenname: this.bookingInfo.contactGivenname,
      //   prefixPhone: this.bookingInfo.prefixPhone,
      //   postfixPhone: this.bookingInfo.postfixPhone,
      //   contactEmail: this.bookingInfo.contactEmail,
      //   members: this.passengersInfo[0],
      // };

      // localStorage.setItem('orderDataBefore', JSON.stringify(dataDummy));
      this.busy = true;
      this.dataOrder.firstSelectedPaymentTransaction = this.selected_payment;
      this.$store
        .dispatch('orderModule/saveDefaultOrderOnline', this.dataOrder)
        .then((data) => {
          const dataPayment = {
            amount: 0,
            orderNumber: data.orderNumber,
            orderId: data.id,
            ticSwitch: data.ticSwitch,
            contactEmail: data.contactEmail,
          };
          if (this.selected_payment === 'deposit') {
            if (!this.depositPayment) {
              dataPayment.amount = data.grandTotal;
            } else {
              dataPayment.amount = this.depositPayment;
            }
          } else {
            dataPayment.amount = this.grandTotal;
          }
          sessionStorage.setItem('dataPayment', JSON.stringify(dataPayment));
          // const paymentUrl = window.location.origin + '/customer/#/payment';
          // window.location.assign(paymentUrl);
          this.$router.push('/payment');
        })

        .catch((error) => {
          if (error.message.includes('The available seats')) {
            const availableSeats = parseInt(error.message.split(' ')[5], 10);
            if (availableSeats > 0) {
              this.bookedCountCurrent = this.max_booking;
              this.bookingInfo.ticket = availableSeats;
              this.passengersInfo = this.passengersInfo.slice(
                0,
                availableSeats,
              );
            }
          }
          sessionStorage.removeItem('dataPayment');
          this.$vToastify.setSettings({
            errorDuration: 15000,
            successDuration: 10000,
          });
          this.$vToastify.error({
            body: error.message,
            defaultTitle: false,
          });
          this.busy = false;
        });
    },

    checkOthers: function (index) {
      if (this.passengersInfo[index].othersCheck == true) {
        this.passengersInfo[index].other = this.departures.otherPrice;
      } else {
        this.passengersInfo[index].other = 0;
      }
      this.reCalculateToTalAmountPassenger(index);
    },

    reCalculateToTalAmountPassenger: function (index) {
      let extraPrices = 0;
      let passenger = this.passengersInfo[index];
      if (passenger.extraBedType) {
        extraPrices += this.departures.extraBed;
      }

      if (passenger.singleRoomType) {
        extraPrices += this.departures.singleRoom;
      }

      if (passenger.childrenBedType) {
        extraPrices += this.departures.childrenOccupyBed;
      }

      let memberDiscount = passenger.memberDiscount;
      if (passenger.memberDiscountCheck !== 1) {
        if (passenger.memberDiscount > 0) {
          memberDiscount = 0;
        }
      }

      let specialDiscount = passenger.specialDiscount
        ? passenger.specialDiscount
        : 0;
      if (passenger.memberSpecialDiscountCheck !== 1) {
        if (passenger.specialDiscount > 0) {
          specialDiscount = 0;
        }
      }

      passenger.amount =
        passenger.unitPrice +
        passenger.tax +
        passenger.visaFee +
        this.departures.tips +
        passenger.insurance +
        passenger.other +
        extraPrices -
        passenger.promotionDiscount -
        memberDiscount -
        specialDiscount;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    calculateAge: function (birthdateString) {
      const today = new Date();
      const birthdate = new Date(birthdateString);
      const age =
        today.getFullYear() -
        birthdate.getFullYear() -
        (today.getMonth() < birthdate.getMonth() ||
          (today.getMonth() === birthdate.getMonth() &&
            today.getDate() < birthdate.getDate()));
      return age;
    },
    changeBirthDay: function (date, index) {
      const age = this.calculateAge(date);
      this.passengersInfo[index].age = age;
      if (age > 11) {
        //setting category
        this.passengersInfo[index].category = 'Adult';
        this.passengersInfo[index].categoryChina = '(成人)';
        //setting insurance
        if (this.passengersInfo[index].insuranceCheck !== 1) {
          this.passengersInfo[index].insurance = 0;
        } else {
          this.passengersInfo[index].insurance = this.departures
            .adultInsuranceFee
            ? this.departures.adultInsuranceFee
            : 0;
        }

        //setting tax
        this.passengersInfo[index].tax = this.departures.adultTax
          ? this.departures.adultTax
          : 0;
        //setting unit price
        this.passengersInfo[index].unitPrice = this.departures.adultPrice;

        //setting tile name
        (this.passengersInfo[index].titleEngName = 'MR'),
          (this.passengersInfo[index].optionsNameEng = [
            { value: 'MR', text: 'MR' },
            { value: 'MRS', text: 'MRS' },
            { value: 'MS', text: 'MS' },
          ]);
        (this.passengersInfo[index].titleChinaName = 'MR'),
          (this.passengersInfo[index].optionsNameChina = [
            { value: 'MR', text: '先生' },
            { value: 'MRS', text: '女士' },
            { value: 'MS', text: '小姐' },
          ]);
      } else {
        //setting category
        if (age >= 2) {
          //setting tax
          this.passengersInfo[index].tax = this.departures.childrenTax
            ? this.departures.childrenTax
            : 0;
          //setting category
          this.passengersInfo[index].category = 'Children';
          this.passengersInfo[index].categoryChina = '(兒童)';
          //setting unit price
          this.passengersInfo[index].unitPrice = this.departures.childrenPrice;
        } else {
          //setting tax
          this.passengersInfo[index].tax = 0;
          //setting category
          this.passengersInfo[index].category = 'Infant';
          this.passengersInfo[index].categoryChina = '(嬰兒)';
          //setting unit price
          this.passengersInfo[index].unitPrice = this.departures.babyPrice;
        }
        //setting insurance
        if (this.passengersInfo[index].insuranceCheck !== 1) {
          this.passengersInfo[index].insurance = 0;
        } else {
          this.passengersInfo[index].insurance = this.departures
            .childrenInsuranceFee
            ? this.departures.childrenInsuranceFee
            : 0;
        }

        (this.passengersInfo[index].titleEngName = 'MSTR'),
          (this.passengersInfo[index].optionsNameEng = [
            { value: 'MSTR', text: 'MSTR' },
            { value: 'MISS', text: 'MISS' },
          ]);
        (this.passengersInfo[index].titleChinaName = 'MSTR'),
          (this.passengersInfo[index].optionsNameChina = [
            { value: 'MSTR', text: '男童' },
            { value: 'MISS', text: '女童' },
          ]);
      }
      this.reCalculateToTalAmountPassenger(index);
    },
    checkVisaFee: function (index) {
      if (this.passengersInfo[index].visaCheck == 1) {
        this.passengersInfo[index].visaFee = this.departures.visaFee;
      } else {
        this.passengersInfo[index].visaFee = 0;
      }
      this.reCalculateToTalAmountPassenger(index);
    },

    checkOthers: function (index) {
      if (this.passengersInfo[index].othersCheck == true) {
        this.passengersInfo[index].other = this.departures.otherPrice;
      } else {
        this.passengersInfo[index].other = 0;
      }
      this.reCalculateToTalAmountPassenger(index);
    },

    checkInsuranceFee: function (index) {
      if (this.passengersInfo[index].insuranceCheck !== 1) {
        this.passengersInfo[index].insurance = 0;
      } else {
        if (this.passengersInfo[index].age > 11) {
          this.passengersInfo[index].insurance = this.departures
            .adultInsuranceFee
            ? this.departures.adultInsuranceFee
            : 0;
        } else {
          this.passengersInfo[index].insurance = this.departures
            .childrenInsuranceFee
            ? this.departures.childrenInsuranceFee
            : 0;
        }
      }
      this.reCalculateToTalAmountPassenger(index);
    },
    checkUserMember(index) {
      let passenger = this.passengersInfo[index];
      const userEmail = passenger.email;
      const userPhone = passenger.contact;
      const prefixPhone = passenger.prefixPhone;
      const departureCurrent = this.departures;
      if (!userEmail || !userPhone) {
        passenger.memberDiscount = 0;
        this.reCalculateToTalAmountPassenger(index);
        return;
      }
      this.$store
        .dispatch('authModule/getUserByFillter', {
          email: userEmail,
          userPhone: userPhone,
          phonePrefix: prefixPhone,
        })
        .then((data) => {
          data = data.returnData;
          if (data.member == 'Y') {
            passenger.memberDiscount = departureCurrent.memberPrice
              ? departureCurrent.memberPrice
              : 0;
          } else {
            passenger.memberDiscount = 0;
          }
          this.reCalculateToTalAmountPassenger(index);
        })
        .catch((error) => {
          if (passenger.memberDiscount != 0) {
            passenger.memberDiscountCheck = 0;
          }
          passenger.memberDiscount = 0;
          this.reCalculateToTalAmountPassenger(index);
        });
    },
    checkUserMemberDiscount: function (index) {
      let passenger = this.passengersInfo[index];
      passenger.specialDiscount = this.departures.specialDiscount
        ? this.departures.specialDiscount
        : 0;
      if (passenger.memberSpecialDiscountCheck === 0) {
        passenger.specialDiscount = 0;
      }
      this.reCalculateToTalAmountPassenger(index);
    },

    changeTitleName: function (index) {
      let passenger = this.passengersInfo[index];
      passenger.titleChinaName = this.optionsTitleNameChina.filter((obj) => {
        return obj.value === passenger.titleEngName;
      })[0].value;
    },

    changeCercificate: function (index) {
      let passenger = this.passengersInfo[index];
      if (passenger.typeOfCertificate === 'OTHER') {
        passenger.typeOfCertificate = '';
        passenger.typeOfCertificateOther = true;
        return;
      }
      passenger.placeOfIssue = this.optionsTypeOfCertificate.filter((obj) => {
        return obj.value === passenger.typeOfCertificate;
      })[0].key;
    },

    changeOptionCertificate: function (index) {
      let passenger = this.passengersInfo[index];
      passenger.typeOfCertificateOther = false;
      passenger.typeOfCertificate = null;
      passenger.placeOfIssue = null;
    },
    handleCollectPointChange(value, index) {
      this.passengersInfo.map((v) => {
        if (!v.collectionPoint) v.collectionPoint = value;
      });
    },
  },
  mounted() {
    if (localStorage.getItem('lang_frontend') == 'en') {
      this.localLanguage = 'en';
    } else if (localStorage.getItem('lang_frontend') == 'cn') {
      this.localLanguage = 'cn';
    } else {
      this.localLanguage = 'hk';
    }
    this.$store.dispatch('tourModule/configData', {}).then((data) => {
      this.seatRequirementList = data.seatRequirements;
    });
    this.userType = this.$store.getters['authModule/getUserType'];

    sessionStorage.removeItem('before_url');
    sessionStorage.removeItem('timeline');
    const departureId = sessionStorage.getItem('departureId');
    sessionStorage.removeItem('departureId');
    Promise.all([
      this.$store.dispatch('tourModule/fetchDepartureData', departureId),
      this.$store.dispatch('orderModule/getOrderLastHistory', {}),
    ]).then(([res1, data]) => {
      this.collectionPointsList = res1.collectionPoints;
      const booked = sessionStorage.getItem('bookedCount');
      this.bookedCountCurrent = parseInt(booked) + 1;
      this.departures = this.info;
      this.departures.adultTax =
        this.info.departureTaxAdult +
        this.info.fuelSurchargeAdult +
        this.info.portTaxAdult +
        this.info.securityChargeAdult;
      this.departures.childrenTax =
        this.info.departureTaxChildren +
        this.info.fuelSurchargeChildren +
        this.info.portTaxChildren +
        this.info.securityChargeChildren;

      this.changeBirthDay(new Date(), 0);
      if (
        this.departures.allowDepositSwitch == 'N' ||
        !this.departures.allowDepositSwitch
      ) {
        this.selected_payment = 'full';
      } else {
        this.selected_payment = 'deposit';
        this.minDepositPayment = this.departures.minDepositPayment
          ? this.departures.minDepositPayment
          : 0;
        if (this.minDepositPayment === 0) {
          this.selected_payment = 'full';
        }
        this.minDepositTour = this.departures.minDepositPayment;
      }

      // if(data.members[0].memberDiscountCheck=='1'&&data.members[0].memberDiscount!=='0'){
      if (data.member && data.member[0]) {
        this.passengersInfo[0].memberDiscount = res1.memberPrice
          ? res1.memberPrice
          : 0;
        this.passengersInfo[0].memberDiscountCheck =
          data.members[0].memberDiscountCheck;
        this.passengersInfo[0].email = data.members[0].email;
        this.passengersInfo[0].contact = data.members[0].contact;
      }
      // }
      this.bookingInfo.departureDate = this.departures.departureDate;
      this.max_booking = parseInt(this.departures.maxPax);
      this.passengersInfo[0].unitPrice = this.departures.babyPrice;
      this.passengersInfo[0].tax = 0;
      this.passengersInfo[0].extraBedPrice = this.departures.extraBed;
      this.passengersInfo[0].singleRoomPrice = this.departures.singleRoom;
      this.passengersInfo[0].childrenBedPrice =
        this.departures.childrenOccupyBed;
      this.passengersInfo[0].tip = this.departures.tips;
      // this.passengersInfo[0].other = this.departures.otherPrice;
      this.passengersInfo[0].promotionDiscount = this.departures
        .promotionDiscount
        ? this.departures.promotionDiscount
        : 0;

      this.passengersInfo[0].fuelSurchargeAdult =
        this.departures.fuelSurchargeAdult;

      this.passengersInfo[0].fuelSurchargeChildren =
        this.departures.fuelSurchargeChildren;

      this.passengersInfo[0].securityChargeAdult =
        this.departures.securityChargeAdult;

      this.passengersInfo[0].securityChargeChildren =
        this.departures.securityChargeChildren;

      this.passengersInfo[0].departureTaxAdult =
        this.departures.departureTaxAdult;

      this.passengersInfo[0].departureTaxChildren =
        this.departures.departureTaxChildren;

      this.passengersInfo[0].portTaxAdult = this.departures.portTaxAdult;

      this.passengersInfo[0].portTaxChildren = this.departures.portTaxChildren;

      const passengerInfo = this.passengersInfo[0];
      this.passengersInfo[0].amount =
        passengerInfo.unitPrice +
        passengerInfo.tax +
        this.departures.tips +
        passengerInfo.insurance -
        passengerInfo.promotionDiscount;

      this.grandTotal = this.passengersInfo[0].amount;
      if (this.minDepositPayment >= this.grandTotal) {
        this.depositPayment = 0;
      } else {
        this.depositPayment = this.minDepositPayment;
      }
      this.balancePayment = this.grandTotal - this.depositPayment;
      this.datapost = {
        departureDate: new Date(this.bookingInfo.departureDate),
        packageCode: this.departures.packageCode,
      };
      sessionStorage.removeItem('bookedCount');
      // })
      // .catch((error) => {});

      // this.$store.dispatch('orderModule/getOrderLastHistory', {}).then((data) => {
      if (data.members && data.members.length > 0) {
        let prefixPhone = null;
        let contact = data.contactPhone;
        if (data.contactPhone)
          this.options_phone.forEach((item) => {
            let textSearch = data.contactPhone.indexOf(item.text);
            if (textSearch != -1) {
              prefixPhone = item.text;
              contact = contact.replace(item.text, '');
              return;
            }
          });
        const dataDummy = {
          contactTitle: data.contactTitle,
          contactSurname: data.contactSurname,
          contactGivenname: data.contactGivenname,
          prefixPhone: prefixPhone,
          postfixPhone: contact,
          contactEmail: data.contactEmail,
          members: data.members ? data.members[0] : [],
        };
        this.bookingInfo.contactTitle = data.contactTitle;
        this.bookingInfo.prefixPhone = prefixPhone;
        this.changeBirthDay(new Date(data.members[0].birthday), 0);
        this.passengersInfo[0].birthday = moment(
          data.members[0].birthday,
        ).format('YYYY-MM-DD');
        this.passengersInfo[0].dateOfExpiry = data.members[0].dateOfExpiry;
        // ? data.members[0].dateOfExpiry
        // : moment(data.members[0].dateOfExpiry).format('YYYY-MM-DD');
        this.passengersInfo[0].prefixPhone = data.members[0].prefixPhone;
        this.passengersInfo[0].typeOfCertificate =
          data.members[0].typeOfCertificate;
        this.passengersInfo[0].passportNumber = data.members[0].passportNumber;
        this.passengersInfo[0].placeOfIssue = data.members[0].placeOfIssue;
        // this.passengersInfo[0].collectionPoint = data.members[0].collectionPoint;
        this.passengersInfo[0].seatRequirement =
          data.members[0].seatRequirement;
        this.passengersInfo[0].titleEngName = data.members[0].titleEngName;
        this.passengersInfo[0].titleChinaName = data.members[0].titleChinaName;
        this.orderDataBefore = dataDummy;
        // });
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/tour')) {
      next();
    } else {
      next('/');
    }
    next();
  },
};
</script>
<style type="text/css" scoped>
.limitClass .custom-checkbox.b-custom-control-sm,
.limitClass .input-group-sm .custom-checkbox {
  font-size: inherit;
}
.font12 {
  font-size: 12px;
}

.pointer-events {
  pointer-events: none;
}

.title-stitle {
  border-left: 8px solid #006bba;
  padding-left: 10px;
}

.background-passenger {
  background-color: #e0eaeb47;
  padding: 10px 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.checkout-detail-content {
  background-color: #fff;
}
.form-input-contact-phone label,
.form-input-contact-phone span.mt-2.pt-2.ck-error {
  margin-left: 0 !important;
}
.booking-phone-prefix {
  height: calc(1.18em + 1.06rem + 1px);
  border: 1px solid rgb(209, 210, 219);
  box-shadow: unset !important;
  padding: 0px 5px;
}
table.ck-table thead tr {
  border-top: 3px solid #1997f8;
}
table.ck-table thead th {
  color: #1997f8;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
table.ck-table tbody td {
  text-align: center;
  font-size: 14px;
}
.ck-section-1.p-4 {
  background-color: #f3f6f8;
  margin-bottom: 1rem;
}
.ck-section-2 {
  margin-bottom: 1rem;
}
hr.new2 {
  border-top: 1px dashed #999999;
}
.checkout-title h4 {
  font-size: 1.2rem;
  color: #0e2b33;
}
.form-group.col-md-2.ck-iput-2 {
  flex: 0 0 35%;
  max-width: 35%;
}
.form-group.col-md-6.ck-iput-7 {
  flex: 0 0 52.5%;
  max-width: 52.5%;
}
.checkout-title h4 > span {
  font-size: 13px;
  border-left: 4px solid #006bba;
  padding-right: 15px;
  line-height: 2;
}
.ck-package h4 > span {
  color: #2296df;
}
.ck-date p {
  color: #333333;
}
.ck-package h4 {
  font-size: 1.2rem;
  color: #333333;
  font-weight: 400;
}
.ck_ticket_number {
  line-height: 2;
  height: 1.6rem;
  width: 4rem;
  border-color: rgb(122 123 151 / 50%);
  text-align: center;
}
.ck-up-number,
.ck-down-number {
  width: 1.8rem;
  background-color: #f6a604;
  color: #fff;
  border: 1px solid;
}
.ck-row {
  color: #2296df;
  font-size: 1.2rem;
}
.ck-row a {
  color: #2296df;
}

.ck-details input,
.ck-details select {
  border-radius: unset;
  height: calc(1.1em + 1.06rem + 2px);
}

.ck-details textarea {
  border-radius: unset;
}
.ck-details .form-group.col-md-5.ck-iput-2 input {
  width: 90%;
}
.ck-details .form-group.col-md-3.ck-iput-3 input {
  width: 240px;
}
.ck-details label > span {
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1.5px;
}
.ck-details .ck-iput-4 select {
  float: right;
}

table.ck-table {
  background-color: #fff;
}
table.ck-table thead th {
  background-color: #fff;
}

.bv-no-focus-ring {
  display: inline-flex;
}

.bv-no-focus-ring > span {
  color: #f66c4b;
  font-weight: 400;
  font-size: 14px;
  margin-left: 3px;
}

.c-red {
  color: #f66c4b;
}
.ck-iput-t3 input,
.ck-iput-t3 label,
.ck-iput-t3 span.ck-error {
  margin-left: 4.2rem;
}
.ck-iput-t2 input,
.ck-iput-t2 label,
.ck-iput-t2 span.ck-error {
  margin-left: 2rem;
}
.ck-error {
  color: #f66c4b;
  font-size: 0.88rem;
  /*letter-spacing: 1.5px;*/
  /*line-height: 3;*/
}
.ck-s3-radio-text label {
  color: #2296df;
  width: 260px;
}
.ck-s3-radio-text label > span {
  color: #f6a604;
  font-weight: unset;
}
.ck-payment-method .custom-radio label {
  color: #2296df;
  padding: 0.1rem 3rem;
  width: 260px;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #2296df;
}
.ck-payment-method span {
  margin-left: 21rem;
}
button.ck-submit {
  background-color: #f6a604;
  border: 1px solid #f6a604;
}
.ck-date .datepicker-popup {
  display: none;
}
.ck-details .departures-price {
  width: 100%;
}
.total-payment-sum {
  font-size: 1.1rem;
  color: #333333;
  font-weight: 400;
}

.col-md-2.bk-col-submit {
  flex: 0 0 18.5%;
  max-width: 18.5%;
}
.datepicker input {
  height: calc(1.2em + 1.06rem + 2px);
}

.ck-distance span {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}
#modal-waring-bk .modal-content {
  border-radius: unset;
  border: unset;
}

.package-checkout-page {
  background-color: #e0eaeb47;
}
header.header-global {
  background: #fff;
}

@media (min-width: 768px) {
  .form-group.col-md-2.ck-iput-1,
  .form-group.col-md-3.ck-iput-5,
  .form-group.col-md-3.ck-iput-6 {
    flex: 0 0 19%;
    max-width: 19%;
  }

  .form-group.col-md-2.ck-iput-4 {
    flex: 0 0 11%;
    max-width: 11%;
  }

  .form-group.col-md-2.ck-iput-2 {
    flex: 0 0 42%;
    max-width: 42%;
  }

  .ck-details .ck-iput-4 label {
    margin-left: 20px;
  }

  .ck-s3-radio-text label {
    width: auto;
  }
}
.ck-details label > span.extra_fee {
  color: #f66c4b;
  font-weight: 400;
  font-size: 12px;
  margin-left: 3px;
}
</style>
